import React, { useState, useEffect } from "react";
import Image02 from "../../images/companyImg2.jpg"
import Image01 from "../../images/companyImg1.jpg"
import Image03 from "../../images/company03.jpeg"

import "./index.scss";

function Company() {
  const [imageIndex, setImageIndex] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if(imageIndex === 0) {
        setImageIndex(1);
      } else if(imageIndex === 1) {
        setImageIndex(2);
      } else {
        setImageIndex(0);
      }
      // setImageIndex();
    }, 8000);
  }, [imageIndex]);

  return (
    <div className="Company" id="Company">
      <div className="Images">
        <div className={imageIndex === 0 ? "ImageHolder" : "ImageHolder Hidden"}>
          <img className="Image" src={Image02} alt="company01" />
          <div className="Filter" />
          <div className="ImageDescription">
            <h2>Reflecting Customer Position</h2>
            <br/>
            <p>고객의 관점에서 생각하고 행동합니다.</p>
          </div>
        </div>
        <div className={imageIndex === 1 ? "ImageHolder" : "ImageHolder Hidden"}>
          <img className="Image" src={Image01} alt="company02" />
          <div className="Filter" />
          <div className="ImageDescription">
            <h2>Customized Professional Care</h2>
            <br/>
            <p className="PC">고객이 필요한 서비스를 맞춤형으로 제공합니다.</p>
            <p className="Mobile">고객이 필요한 서비스를<br />맞춤형으로 제공합니다.</p>
          </div>
        </div>
        <div className={imageIndex === 2 ? "ImageHolder" : "ImageHolder Hidden"}>
          <img className="Image" src={Image03} alt="company03" />
          <div className="Filter" />
          <div className="ImageDescription">
            <h2>With HelloExpert, we're all experts</h2>
            <br/>
            <p>HelloExpert와 함께라면, 우리는 모두 전문가입니다.</p>
          </div>
        </div>

      </div>
      <div className="Title">HelloExpert is...</div>
      <div className="Content">
        노코드 템플릿 기반 앱빌더를 제공하는 스타트업입니다.<br /> 유저의 입장에서 불편한 점을 찾고, 유저 친화적인 기술을 바탕으로 쉽고, 완성도 높은 서비스를 제공하고자 합니다.<br />
        헬로우엑스퍼트를 통하면, 우리 모두 전문가입니다.
      </div>
    </div>
  );
}

export default Company;
