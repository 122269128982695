import React, { useEffect, useState } from "react";
import { sendInquiry } from "../../utils/httpRequest";
import "./index.scss";

function Support() {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setValid] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const onChangeName = e => setName(e.target.value);
  const onChangeContact = e => setContact(e.target.value);
  const onChangeMessage = e => setMessage(e.target.value);

  useEffect(() => setValid(name.length > 2 && contact.length > 5 && message.length > 5), [name, contact, message]);

  const onSubmit = async () => {
    if(!isValid) return;
    try{
      await sendInquiry({name, contact, message});
      setName("");
      setContact("");
      setMessage("");
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1500);
    }catch(e) {
      console.error(e);
    }
  }
  return (
    <div className="Support" id="Support">
      <div className="Title">업무 제휴 문의</div>
      <div className="Row">
        <label>이름</label>
        <input type="text" value={name} onChange={onChangeName} placeholder="이름을 입력하세요." />
      </div>
      <div className="Row">
        <label>연락처</label>
        <input type="text" value={contact} onChange={onChangeContact} placeholder="이메일 또는 휴대전화" />
      </div>
      <div className="Row">
        <label>내용</label>
        <textarea value={message} onChange={onChangeMessage} placeholder="내용을 입력해주세요." />
      </div>
      <div className="Row">
        <button className={isValid ? "valid" : "invalid"} onClick={onSubmit}>전송하기</button>
      </div>
      <div className={isSuccess ? "Success" : "Success Hidden"}>
        <div>문의가 전송되었습니다.<br />감사합니다!</div>
      </div>
    </div>
  );
}

export default Support;
