import React, { useState } from "react";
import "./index.scss";
import { MdClose, MdMenu }  from "react-icons/md";
//https://react-icons.github.io/react-icons/icons?name=fi


const NavItems = [
  {key: "home", name: "홈", link: "#Home"},
  {key: "company", name: "회사 소개", link: "#Company"},
  {key: "service", name: "서비스 소개", link: "#Service"},
  {key: "support", name: "문의하기", link: "#Support"},
  {key: "map", name: "찾아오시는 길", link: "#Map"},
];

function Navbar() {
  const [isVisible, setVisible] = useState(false);
  const onClick = link => {
    window.location.href = link;
    setVisible(false);
  }
  return (
    <div className={isVisible ? "Navbar" : "Navbar Hidden"}>
      {NavItems.map((item)=> {
        return (
          <div onClick={()=>onClick(item.link)} className="NavItem" key={item.key}>{item.name}</div>
        );
      })}
      {isVisible ? 
        <MdClose className="Menu" onClick={()=>setVisible(false)} /> :
        <MdMenu className="Menu" onClick={()=>setVisible(true)} />
      }
    </div>
  );
};

export default Navbar;
