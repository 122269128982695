import { BASE_URL } from "./constants";
// import * as auth from "./auth";

const successCodes = [200];
const fetchRequest = async (method, url, params) => {
  const headers = { "Content-Type": "application/json; charset=utf-8" };
  
  // if(auth.getAuthToken()) {
  //   headers["Authorization"] = auth.getAuthToken();
  // }

  const fetchOptions = { method, headers };
  if(params) fetchOptions.body = JSON.stringify(params);

  return new Promise((resolve, reject) => {
    fetch(BASE_URL + url, fetchOptions)
      .then(res => res.json())
      .then(json => {
        if(successCodes.includes(json.code)) {
          resolve(json.data);
        }else {
          console.error(json);
          reject(json);
        }
      })
      .catch(err => {
        console.error(err);
        reject(err)
      });
  });
};

// monitor
export const sendInquiry = params => fetchRequest("POST", "/homepage/inquiry", params);