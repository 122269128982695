import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Company from "../components/Company";
import Service from "../components/Service";
import Support from "../components/Support";
import Map from "../components/Map";
import Footer from "../components/Footer";
import "./Home.scss";

function Home() {
  return (
    <div className="Container">
      <Header />
      <Navbar />
      <Company />
      <Service />
      <Support />
      <Map />
      <Footer />
    </div>
  );
}

export default Home;
