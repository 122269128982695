import React from "react";
import "./index.scss";

function Header() {
  return (
    <div className="Header" id="Home">
      (주)헬로우엑스퍼트
    </div>
  );
}

export default Header;
