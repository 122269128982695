import React, { useEffect, useRef } from "react";
import "./index.scss";

function Map() {
  const mapElement = useRef(null);


  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng(37.1928868,127.0266975);
    const mapOptions: naver.maps.MapOptions = {
      center: location,
      zoom: 16,
      zoomControl: false
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);

  return (

    <div className="Map" id="Map">
      <div className="Wrap">
      <div className="Title">찾아오시는 길</div>
      <div className="Content">경기도 오산시 한신대길 137, 한신대학교 창업보육센터 1층 9106호</div>
      <div ref={mapElement} className="MapHolder" />
      </div>
    </div>
  );
}

export default Map;