import React from "react";
import Image02 from "../../images/serviceImg1.png"
import Image03 from "../../images/serviceImg2.png"
import Image04 from "../../images/serviceImg3.jpg"
// import { APPSTORE_URL, PLAYSTORE_URL } from "../../utils/constants";
import "./index.scss";

function Service() {
  return (
    <div className="Service" id="Service">
      {/* <div className="AppInfo">
        <img className="Image main" src={Image09} alt="service" />
      </div>
      <div className="Content">
        식물을 이제 막 접한 초보 식린이분들이 식집사로 거듭나는 그날까지! <br/>
        나의 정원은 여러분들을 응원합니다.
      </div>
      <div className="Stores">
        <a href={PLAYSTORE_URL} target="_blank" rel="noreferrer">
          <img className="Image button" src={Image07} alt="service" />
        </a>
        <a href={APPSTORE_URL} target="_blank" rel="noreferrer">
          <img className="Image button" src={Image08} alt="service" />
        </a>
      </div> */}
      {/* <div className="Title">노코드앱 빌더란</div>
      <div className="Content">미리 준비된, 각종 유형의 앱에서 자주 사용되는 앱의 화면(템플릿)을 사용자는 코딩 없이 이를 직접 조합/수정해서 필요에 맞게 원하는 앱을 손쉽게 만들 수 있는 웹사이트 플랫폼이며, 손쉬운 방법에 높은 품질로 상업적 사용 수준의 앱을 만들고, 플레이스토어/앱스토어에도 직접 만든 앱을 배포할 수도 있습니다.</div> */}
      <Sections />
    </div>
  );
}

function Sections() {
  return (
    <div className="Sections">
      <Section01/>
      <Section02 />
      <Section03 />
    </div>
  );
}

function Section01() {
  return (
    <div className="Section Section01">
      <div className="Title">노코드앱 템플릿 기반 앱빌더란</div>
      <div className="Content">각종 유형의 앱에서 자주 사용되는 앱의 화면(템플릿)을 기반으로<br /> 사용자는 코딩 없이 이를 직접 조합/수정해서 필요에 맞게 원하는 앱을 손쉽게 만들 수 있는 웹사이트 플랫폼이며,<br /> 손쉽게 높은 품질로 상업적 사용 수준의 앱을 만들고,<br /> 플레이스토어/앱스토어에도 직접 만든 앱을 배포할 수도 있습니다.</div>
      <div className="SectionWrap">
        <img className="Image" src={Image02} alt="service" />
        <div className="PC Description">웹 기반으로 설치 없이<br />코딩 없이 클릭만으로<br />템플릿 기반으로 더욱 빠르게</div>
        <div className="Mobile Description">웹 기반으로 설치 없이<br />코딩 없이 클릭만으로<br />템플릿 기반으로 더욱 빠르게</div>
      </div>
    </div>
  );
}
function Section02() {
  return (
    <div className="Section Section02">
      <div className="SectionWrap">
        <div className="PC Description">실제 활용 가능한 실용적인 앱<br />ChatGPT, WebRTC등 전문 기술도 쉽게</div>
        <div className="Mobile Description">실제 활용 가능한 실용적인 앱<br />ChatGPT, WebRTC등 전문 기술도 쉽게</div>
        <img className="Image" src={Image03} alt="service" />
      </div>
    </div>
  );
}
function Section03() {
  return (
    <div className="Section Section03">
      <div className="SectionWrap">
        <img className="Image" src={Image04} alt="service" />
        <div className="PC Description">간단하지만 높은 퀄리티<br />내가 만든 앱을 앱스토어/플레이스토어에 배포까지</div>
        <div className="Mobile Description">간단하지만 높은 퀄리티<br />내가 만든 앱을 앱스토어/플레이스토어에 배포까지</div>
      </div>
    </div>
  );
}

export default Service;
