import React from "react";
import "./index.scss";

function Footer() {
  return (
    <div className="Footer">
      Copyright © 2023 HelloExpert Inc. All rights reserved<br />
      (주)헬로우엑스퍼트 <a href="mailto:info@helloexpert.co.kr">info@helloexpert.co.kr</a><br />
      경기도 오산시 한신대길 137, 한신대학교 창업보육센터 1층 9106호
    </div>
  );
}

export default Footer;
